<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        label-width="120"
        v-model="query.more.regAddress"
        name="工商注册地址"
        label="工商注册地址"
        placeholder="请输入工商注册地址"
      />
      <van-field
        readonly
        label-width="120"
        clickable
        name="单位类型"
        :value="comSort"
        label="单位类型"
        placeholder="点击选择单位类型"
        @click="showPicker1 = true"
      />
      <van-field
        readonly
        label-width="120"
        clickable
        name="所属环保局"
        :value="inEpa"
        label="所属环保局"
        placeholder="点击选择所属环保局"
        @click="showPicker4 = true"
      />
      <van-field
        label-width="120"
        v-model="query.more.envStaffNums"
        name="专职环保人员数"
        label="专职环保人员数"
        placeholder="请输入专职环保人员数"
      />
      <van-field
        readonly
        clickable
        label-width="120"
        :value="divisionAreaIds"
        label="所属行政区"
        placeholder="点击选择所属行政区"
        @click="showPicker0 = true"
      />

      <van-popup v-model="showPicker0" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择省市区"
          :options="columns0"
          :field-names="fieldNames"
          @close="showPicker0 = false"
          @finish="onFinish"
        />
      </van-popup>

      <van-field
        readonly
        label-width="120"
        clickable
        name="选择街道"
        :value="divisionAreaId"
        label="选择街道"
        placeholder="点击选择街道"
        @click="showPicker5 = true"
      />
      <van-field
        label-width="120"
        v-model="query.more.yearStaffNums"
        name="年末职工总数"
        label="年末职工总数"
        placeholder="请输入年末职工总数"
      />
      <van-field
        label-width="120"
        v-model="query.more.yearOutputValue"
        name="年总产值(万元)"
        label="年总产值(万元)"
        placeholder="请输入年总产值(万元)"
      />
      <van-field
        label-width="120"
        v-model="query.more.saleMoney"
        name="产品销售额(万元)"
        label="产品销售额(万元)"
        placeholder="请输入产品销售额(万元)"
      />
      <van-field
        label-width="120"
        v-model="query.more.totalInvest"
        name="总投资(万元)"
        label="总投资(万元)"
        placeholder="请输入总投资(万元)"
      />
      <van-field
        label-width="120"
        v-model="query.more.floorSpace"
        name="占地面积"
        label="占地面积(m²)"
        type="number"
        placeholder="请输入占地面积"
      />
      <van-field
        label-width="120"
        v-model="query.more.workAddress"
        name="实施地址"
        label="实施地址"
        placeholder="请输入实施地址"
      />
      <van-field
        readonly
        label-width="120"
        clickable
        name="所属行业"
        :value="businessCategory"
        label="所属行业"
        placeholder="点击选择所属行业"
        @click="showPicker2 = true"
      />
      <van-field
        readonly
        label-width="120"
        clickable
        name="固废产废规模"
        :value="wasteScale"
        label="固废产废规模"
        placeholder="点击选择固废产废规模"
        @click="showPicker3 = true"
      />
      <van-field
        label-width="120"
        v-model="query.more.invoiceReceiveEmail"
        name="发票接收邮箱"
        label="发票接收邮箱"
        placeholder="请输入发票接收邮箱"
      />
      <van-field
        label-width="120"
        v-model="query.more.linkPhone"
        name="固定电话"
        label="固定电话"
        placeholder="请输入固定电话"
      />
      <van-field
        label-width="120"
        v-model="query.more.openBank"
        name="开户行"
        label="开户行"
        placeholder="请输入开户行"
      />
      <van-field
        label-width="120"
        v-model="query.more.bankAccount"
        name="账号"
        label="账号"
        placeholder="请输入账号"
      />
      <van-field
        label-width="120"
        v-model="query.info.categoryScope"
        name="固废类型"
        label="固废类型"
        placeholder="请输入固废类型"
      />
      <van-field
        label-width="120"
        v-model="query.info.disposeFeeIncinerate"
        name="处置费"
        label="处置费(焚烧)"
        type="number"
        placeholder="请输入处置费"
      />
      <van-field
        label-width="120"
        v-model="query.info.weightFreightIncinerate"
        name="重量运费(焚烧)"
        label="重量运费(焚烧)"
        type="number"
        placeholder="请输入按重量运算费"
      />
      <van-field
        label-width="120"
        v-model="query.info.volumeFreightIncinerate"
        name="体积运费(焚烧)"
        label="体积运费(焚烧)"
        type="number"
        placeholder="请输入按体积运算费"
      />
      <van-field
        label-width="120"
        v-model="query.info.disposeFeeLandfill"
        name="处置费"
        label="处置费(填埋)"
        type="number"
        placeholder="请输入处置费"
      />
      <van-field
        label-width="120"
        v-model="query.info.weightFreightLandfill"
        name="重量运费(填埋)"
        label="重量运费(填埋)"
        type="number"
        placeholder="请输入按重量运算费"
      />
      <van-field name="switch" label="是否经过岗位培训" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.instructorIsPro" size="20" />
        </template>
      </van-field>
      <van-field
        label-width="120"
        v-model="query.more.instructor"
        name="固废指导员"
        label="固废指导员"
        placeholder="请输入固废指导员"
      />
      <van-field name="switch" label="是否委托一般工业固废台账的建立和管理" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isTrustBook" size="20" />
        </template>
      </van-field>
      <van-field name="switch" label="是否代办毒性检测" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isDoViral" size="20" />
        </template>
      </van-field>
      <van-field v-if="query.more.isDoViral" name="switch" label="是否收到检测费" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isDoViralFee" size="20" />
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        v-if="query.more.isDoViral"
        name="datetimePicker"
        :value="tiemValue1"
        label-width="120"
        label="出具检测报告时间"
        placeholder="点击选择时间"
        @click="showPicker6 = true"
      />
      <van-field name="switch" label="是否提供环评报告(备案)" label-width="200" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isOfferEnv" size="20" />
        </template>
      </van-field>
      <van-field name="switch" label="是否签订合同" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isSigningContract" size="20" />
        </template>
      </van-field>
      <van-field v-if="query.more.isSigningContract" name="switch" label="是否收到服务费" label-width="260" input-align="right">
        <template #input>
          <van-switch v-model="query.more.isReceiveFee" size="20" />
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        v-if="query.more.isSigningContract"
        name="datetimePicker"
        :value="tiemValue2"
        label-width="120"
        label="合同签订时间"
        placeholder="点击选择时间"
        @click="showPicker7 = true"
      />
      <van-field name="cardFront" label="上传营业执照">
        <template #input>
          <van-uploader
            @delete="deleteCf"
            v-model="businessLicense"
            :max-count="1" />
        </template>
      </van-field>
      
      <van-popup v-model="showPicker6" position="bottom">
        <van-datetime-picker
          type="date"
          title="选择年月日"
          @confirm="onConfirm6"
          @cancel="showPicker6 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker7" position="bottom">
        <van-datetime-picker
          type="date"
          title="选择年月日"
          @confirm="onConfirm7"
          @cancel="showPicker7 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker1" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker3" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns3"
          @confirm="onConfirm3"
          @cancel="showPicker3 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker4" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns4"
          @confirm="onConfirm4"
          @cancel="showPicker4 = false"
        />
      </van-popup>
      <van-popup v-model="showPicker5" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns5"
          @confirm="onConfirm5"
          @cancel="showPicker5 = false"
        />
      </van-popup>
      <div class="beizhu">
        <p>说明：系统会根据您输入的详细地址自动获取对应的经纬度，如果输入的地址不准确，获取经纬度会不准确</p>
      </div>
      <div class="btn">
        <van-button round block type="info" v-if="isShowxiugai" native-type="submit">修改</van-button>
        <van-button round block loading type="info" v-else loading-text="加载中..."></van-button>
      </div>
    </van-form>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { dictType } from '@/api/notice.js'
import { companysave, companyid } from '@/api/enterprise.js'
import { querymoreInfo, editmoreInfo, divisioncode, getDivisionList } from '@/api/order.js'
import { Toast } from 'vant'
import { Dialog } from 'vant'
import pcaCode from '@/assets/data/pca-code.json'

export default {
  data () {
    return {
      isShowxiugai: true,
      amap_key: '3b7a88fa011cc25b116c2cd355292b1d',
      inEpa: undefined, // 选择
      comSort: undefined, // 选择
      businessCategory: undefined, // 选择
      wasteScale: undefined, // 选择
      businessLicense: undefined, // 照片
      divisionAreaId: undefined, // 街道
      divisionAreaIds: undefined, // 行政区
      cascaderValue: undefined, // 弹框行政区
      query: {
        info: {
          comName: undefined, // 企业名称
          uscc: undefined, // 统一征信代码
          balanceTotal: undefined, // 充值币总额
          inEpa: undefined, // 所属环保局
          managerName: undefined, // 环保负责人
          managerPhone: undefined, // 环保负责人电话
          legalName: undefined, // 企业负责人
          legalPhone: undefined, // 企业负责人电话
          disposeFeeIncinerate: undefined, // 处置费
          weightFreightIncinerate: undefined, // 按重量运算
          volumeFreightIncinerate: undefined, // 按体积运算
          disposeFeeLandfill: undefined, // 处置费
          weightFreightLandfill: undefined, // 按重量运算
          categoryScope: undefined, // 固废类型
        },
        more: {
          comId: undefined,
          divisionAreaId: undefined, // 所属行政区域
          comSort: undefined, // 单位类型 1集体
          envStaffNums: undefined, // 专职环保人员数
          floorSpace: undefined, // 占地面积（万平方米）
          workAddress: undefined, // 实施地址
          longitude: undefined,
          latitude: undefined,
          businessCategory: undefined, // 所属行业
          wasteScale: undefined, // 危废产废规模
          businessLicense: undefined, // 营业执照 图片路径
          invoiceReceiveEmail: undefined, // 发票接收邮箱
          openBank: undefined, // 开户行
          bankAccount: undefined, // 账号
          isTrustBook: undefined, // 是否委托一般工业固废台账的建立和管理 0否 1是
          isOfferEnv: undefined, // 是否提供环评报告（备案） 0否 1是
          regAddress: undefined, // 工商注册地址
          yearStaffNums: undefined, // 年末职工总数
          yearOutputValue: undefined, // 年总产值（万元）
          saleMoney: undefined, // 产品销售额（万元）
          totalInvest: undefined, // 总投资（万元）
          linkPhone: undefined, // 固定电话
          instructor: undefined, // 固废指导员
          instructorIsPro: undefined, // 是否经过岗位培训 0=否,1=是
          isSigningContract: undefined, // 是否签订合同
          isReceiveFee: undefined, // 是否收到服务费 0否 1是
          isDoViral: undefined, // 是否代办一般工业固废（含污泥）的毒性检测报告 0否 1是
          isDoViralFee: undefined, // 是否收到检测费
          doViralTime: undefined, // 出具检测报告时间
          signingTime: undefined, // 合同签订时间
        },
      },
      querys: {
        divisionId: undefined,
        level: 4,
      },
      fieldNames: {
        text: 'text',
        value: 'id',
        children: 'children',
      },
      columns0: pcaCode,
      columns1: [],
      columns2: [],
      columns3: [],
      columns4: [],
      columns5: [],
      showPicker0: false,
      showPicker1: false,
      showPicker2: false,
      showPicker3: false,
      showPicker4: false,
      showPicker5: false,
      tiemValue1: '',
      showPicker6: false,
      tiemValue2: '',
      showPicker7: false,
      client: new OSS.Wrapper({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
        accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
        bucket: 'hzjiaduomei-huishou'
      }),
      businessLicenseUrl: undefined,
    }
  },
  created() {
    this.dictType()
  },
  methods: {
    iptChange(item) {
      this.plateNo = item.plateNo
    },
    dictType() { // 字典
      dictType('com_sort').then(res => { // 单位类型
        if (res.data.code === 200) {
          this.columns1 = (res.data.data || []).map(item => {
            return {
              id: item.dictValue,
              text: item.dictLabel
            }
          })
          dictType('business_category').then(res => { // 所属行业
            if (res.data.code === 200) {
              this.columns2 = (res.data.data || []).map(item => {
                return {
                  id: item.dictValue,
                  text: item.dictLabel
                }
              })
              dictType('solid_scale').then(res => { // 危废产废规模
                if (res.data.code === 200) {
                  this.columns3 = (res.data.data || []).map(item => {
                    return {
                      id: item.dictValue,
                      text: item.dictLabel
                    }
                  })
                  dictType('hs_company_inEpa').then(res => { // 所属环保局
                    if (res.data.code === 200) {
                      this.columns4 = (res.data.data || []).map(item => {
                        return {
                          id: item.dictLabel,
                          text: item.dictLabel
                        }
                      })
                      if (this.$route.query.admin) {
                        this.query.more.comId = parseInt(this.$route.query.id)
                        this.companyid()
                      } else {
                        this.querymoreInfo()
                      }
                    }
                  })
                }
              })
            }
          })
        }
      })
    },
    querymoreInfo() { // 获取企业详细信息
      querymoreInfo().then(res => {
        if (res.data.data.more) {
          this.query = res.data.data
          if (res.data.data.more.divisionAreaId) {
            divisioncode(res.data.data.more.divisionAreaId).then(res => {
              this.divisionAreaIds = res.data.data.provinceName + '/' + res.data.data.cityName + '/' + res.data.data.districtName
              this.divisionAreaId = res.data.data.townName
              this.querys.divisionId = res.data.data.districtId
              if (this.querys.divisionId) {
                this.getDivisionList()
              }
            })
          }
          if (!res.data.data.more.comId) {
            this.query.more.comId = parseInt(this.$route.query.id)
          }
          if (this.query.more.comSort) {
            this.comSort = this.columns1.filter(item => item.id == this.query.more.comSort)[0].text
          }
          if (this.query.more.businessCategory) {
            this.businessCategory = this.columns2.filter(item => item.id == this.query.more.businessCategory)[0].text 
          }
          if (this.query.more.wasteScale) {
            this.wasteScale = this.columns3.filter(item => item.id == this.query.more.wasteScale)[0].text 
          }
          if (this.query.more.inEpa) {
            this.inEpa = this.columns4.filter(item => item.id == this.query.info.inEpa)[0].text 
          }
          if (res.data.data.more.businessLicense) {
            this.businessLicense = [ { url: this.client.signatureUrl(res.data.data.more.businessLicense) } ]
            this.businessLicenseUrl = [ { url: this.client.signatureUrl(res.data.data.more.businessLicense) } ]
          }
          if (this.query.more.isTrustBook === 1) {
            this.query.more.isTrustBook = true
          } else {
            this.query.more.isTrustBook = false
          }
          if (this.query.more.isOfferEnv === 1) {
            this.query.more.isOfferEnv = true
          } else {
            this.query.more.isOfferEnv = false
          }
          if (this.query.more.instructorIsPro === 1) {
            this.query.more.instructorIsPro = true
          } else {
            this.query.more.instructorIsPro = false
          }
          if (this.query.more.isSigningContract === 1) {
            this.query.more.isSigningContract = true
          } else {
            this.query.more.isSigningContract = false
          }
          if (this.query.more.isReceiveFee === 1) {
            this.query.more.isReceiveFee = true
          } else {
            this.query.more.isReceiveFee = false
          }
          if (this.query.more.isDoViral === 1) {
            this.query.more.isDoViral = true
          } else {
            this.query.more.isDoViral = false
          }
          if (this.query.more.isDoViralFee === 1) {
            this.query.more.isDoViralFee = true
          } else {
            this.query.more.isDoViralFee = false
          }
        }
      })
    },
    companyid() { // 获取管理员详细信息
      companyid(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.query = res.data.data
          if (res.data.data.more.divisionAreaId) {
            divisioncode(res.data.data.more.divisionAreaId).then(res => {
              this.divisionAreaIds = res.data.data.provinceName + '/' + res.data.data.cityName + '/' + res.data.data.districtName
              this.divisionAreaId = res.data.data.townName
              this.querys.divisionId = res.data.data.districtId
              if (this.querys.divisionId) {
                this.getDivisionList()
              }
            })
          }
          if (!res.data.data.more.comId) {
            this.query.more.comId = parseInt(this.$route.query.id)
          }
          if (this.query.more.comSort) {
            this.comSort = this.columns1.filter(item => item.id == this.query.more.comSort)[0].text
          }
          if (this.query.more.businessCategory) {
            this.businessCategory = this.columns2.filter(item => item.id == this.query.more.businessCategory)[0].text 
          }
          if (this.query.more.wasteScale) {
            this.wasteScale = this.columns3.filter(item => item.id == this.query.more.wasteScale)[0].text 
          }
          this.inEpa = this.query.info.inEpa
          if (res.data.data.more.businessLicense) {
            this.businessLicense = [ { url: this.client.signatureUrl(res.data.data.more.businessLicense) } ]
            this.businessLicenseUrl = [ { url: this.client.signatureUrl(res.data.data.more.businessLicense) } ]
          }
          if (this.query.more.isTrustBook === 1) {
            this.query.more.isTrustBook = true
          } else {
            this.query.more.isTrustBook = false
          }
          if (this.query.more.isOfferEnv === 1) {
            this.query.more.isOfferEnv = true
          } else {
            this.query.more.isOfferEnv = false
          }
          if (this.query.more.instructorIsPro === 1) {
            this.query.more.instructorIsPro = true
          } else {
            this.query.more.instructorIsPro = false
          }
          if (this.query.more.isSigningContract === 1) {
            this.query.more.isSigningContract = true
          } else {
            this.query.more.isSigningContract = false
          }
          if (this.query.more.isReceiveFee === 1) {
            this.query.more.isReceiveFee = true
          } else {
            this.query.more.isReceiveFee = false
          }
          if (this.query.more.isDoViral === 1) {
            this.query.more.isDoViral = true
          } else {
            this.query.more.isDoViral = false
          }
          if (this.query.more.isDoViralFee === 1) {
            this.query.more.isDoViralFee = true
          } else {
            this.query.more.isDoViralFee = false
          }
        }
      })
    },
    deleteCf() {
      Dialog.confirm({
        title: '关闭图片',
        message: '确认关闭图片！！！,图片关闭后需重新选择',
      }).then(() => {
        this.businessLicense = undefined
        this.query.more.businessLicense = ''
      }).catch(() => {
        this.businessLicense = this.businessLicenseUrl
      })
    },
    onSubmit(file) { // 企业管理员修改
      this.isShowxiugai = false
      if (this.query.more.workAddress) {
        this.$axios({
          method: 'get',
          url: 'https://restapi.amap.com/v3/geocode/geo?parameters',
          params: {
            key: this.amap_key,
            address: this.query.more.workAddress
          }
        }).then(res => { // 解析
          if (res.data.status == 1) {
            if (res.data.geocodes.length < 1) {
              return Toast('请输入真实有效的地址')
            }
            const arr = res.data.geocodes[0].location
            this.query.more.longitude = arr.substring(0, arr.lastIndexOf(','))
            this.query.more.latitude = arr.substring(this.query.more.longitude.length + 1, arr.length)
            if (this.query.more.isTrustBook) {
              this.query.more.isTrustBook = 1
            } else {
              this.query.more.isTrustBook = 0
            }
            if (this.query.more.isOfferEnv) {
              this.query.more.isOfferEnv = 1
            } else {
              this.query.more.isOfferEnv = 0
            }
            if (this.query.more.instructorIsPro) {
              this.query.more.instructorIsPro = 1
            } else {
              this.query.more.instructorIsPro = 0
            }
            if (this.query.more.isSigningContract) {
              this.query.more.isSigningContract = 1
            } else {
              this.query.more.isSigningContract = 0
            }
            if (this.query.more.isReceiveFee) {
              this.query.more.isReceiveFee = 1
            } else {
              this.query.more.isReceiveFee = 0
            }
            if (this.query.more.isDoViral) {
              this.query.more.isDoViral = 1
            } else {
              this.query.more.isDoViral = 0
            }
            if (this.query.more.isDoViralFee) {
              this.query.more.isDoViralFee = 1
            } else {
              this.query.more.isDoViralFee = 0
            }
            if (this.businessLicense) {
              if (file.cardFront[0].file) {
                const tempArr = file.cardFront[0].file
                this.uploadDocuments(tempArr)
              } else {
                if (this.$route.query.admin) { // 管理员的修改
                  companysave(this.query).then(res => {
                    if (res.data.code === 200) {
                      Toast('修改成功')
                      this.$router.back()
                    }
                  })
                } else {
                  editmoreInfo(this.query).then(res => { // 企业我的修改
                    if (res.data.code === 200) {
                      Toast('提交成功')
                      this.$router.push({path: 'Profileuser'})
                    }
                  })
                }
              }
            } else {
              if (this.$route.query.admin) { // 管理员的修改
                companysave(this.query).then(res => {
                  if (res.data.code === 200) {
                    Toast('修改成功')
                    this.$router.back()
                  }
                })
              } else {
                editmoreInfo(this.query).then(res => { // 企业我的修改
                  if (res.data.code === 200) {
                    Toast('提交成功')
                    this.$router.push({path: 'Profileuser'})
                  }
                })
              }
            }
          } else {
            Toast('地址解析错误')
            this.isShowxiugai = true
          }
        }) 
      } else {
        this.query.more.longitude = undefined
        this.query.more.latitude = undefined
        if (this.query.more.isTrustBook) {
          this.query.more.isTrustBook = 1
        } else {
          this.query.more.isTrustBook = 0
        }
        if (this.query.more.isOfferEnv) {
          this.query.more.isOfferEnv = 1
        } else {
          this.query.more.isOfferEnv = 0
        }
        if (this.query.more.instructorIsPro) {
          this.query.more.instructorIsPro = 1
        } else {
          this.query.more.instructorIsPro = 0
        }
        if (this.query.more.isSigningContract) {
          this.query.more.isSigningContract = 1
        } else {
          this.query.more.isSigningContract = 0
        }
        if (this.query.more.isReceiveFee) {
          this.query.more.isReceiveFee = 1
        } else {
          this.query.more.isReceiveFee = 0
        }
        if (this.query.more.isDoViral) {
          this.query.more.isDoViral = 1
        } else {
          this.query.more.isDoViral = 0
        }
        if (this.query.more.isDoViralFee) {
          this.query.more.isDoViralFee = 1
        } else {
          this.query.more.isDoViralFee = 0
        }
        if (this.businessLicense) {
          if (file.cardFront[0].file) {
            const tempArr = file.cardFront[0].file
            this.uploadDocuments(tempArr)
          } else {
            if (this.$route.query.admin) { // 管理员的修改
              companysave(this.query).then(res => {
                if (res.data.code === 200) {
                  Toast('修改成功')
                  this.$router.back()
                } else {
                  this.isShowxiugai = true
                }
              })
            } else {
              editmoreInfo(this.query).then(res => { // 企业我的修改
                if (res.data.code === 200) {
                  Toast('提交成功')
                  this.$router.push({path: 'Profileuser'})
                } else {
                  this.isShowxiugai = true
                }
              })
            }
          }
        } else {
          if (this.$route.query.admin) { // 管理员的修改
            companysave(this.query).then(res => {
              if (res.data.code === 200) {
                Toast('修改成功')
                this.$router.back()
              } else {
                this.isShowxiugai = true
              }
            })
          } else {
            editmoreInfo(this.query).then(res => { // 企业我的修改
              if (res.data.code === 200) {
                Toast('提交成功')
                this.$router.push({path: 'Profileuser'})
              } else {
                this.isShowxiugai = true
              }
            })
          }
        }
      }
    },
    uploadDocuments(tempArr) { // 上传证件
      const Name = tempArr.name // 这是file里的name
      const suffix = Name.substr(Name.indexOf('.')) // 文件.后的后缀
      // 文件名:图片名字加上时间戳和9999以内随机数，防止重名
      const filename =`gygf/${new Date().getTime()}${Math.floor(Math.random() * 10000)}${suffix}`
      this.client.multipartUpload(filename, tempArr).then(res => { // 上传
        console.log('上传成功：', res)
        this.query.more.businessLicense = res.name
        if (this.$route.query.admin) { // 管理员的修改
          companysave(this.query).then(res => {
            if (res.data.code === 200) {
              Toast('修改成功')
              this.$router.back()
            } else {
              this.isShowxiugai = true
            }
          })
        } else {
          editmoreInfo(this.query).then(res => { // 企业我的修改
            if (res.data.code === 200) {
              Toast('提交成功')
              this.$router.push({path: 'Profileuser'})
            } else {
              this.isShowxiugai = true
            }
          })
        }
      }).catch(err => {
        console.log('上传失败：', err)
        Toast('上传失败')
        this.isShowxiugai = true
      })
    },
    getDivisionList() { // 获取街道
      getDivisionList(this.querys).then(res => {
        if (res.data.code === 200) {
          this.columns5 = (res.data.data.divisions || []).map(item => {
            return {
              id: item.divisionId,
              text: item.name
            }
          })
        }
      })
    },
    onFinish({ selectedOptions }) {
      this.showPicker0 = false
      this.divisionAreaId = ''
      this.divisionAreaIds = selectedOptions.map((option) => option.text).join('/');
      const codes = selectedOptions.map((option) => option.id).slice(-1)[0]
      if (codes) {
        this.querys.divisionId = codes
        this.getDivisionList()
      }
    },
    onConfirm1(value) {
      this.showPicker1 = false;
      this.query.more.comSort = parseInt(value.id)
      this.comSort = value.text
    },
    onConfirm2(value) {
      this.showPicker2 = false;
      this.query.more.businessCategory = value.id
      this.businessCategory = value.text
    },
    onConfirm3(value) {
      this.showPicker3 = false;
      this.query.more.wasteScale = value.id
      this.wasteScale = value.text
    },
    onConfirm4(value) {
      this.showPicker4 = false;
      this.query.info.inEpa = value.id
      this.inEpa = value.text
    },
    onConfirm5(value) {
      this.showPicker5 = false;
      this.query.more.divisionAreaId = value.id
      this.divisionAreaId = value.text
    },
    onConfirm6(time) {
      this.tiemValue1 = this.conversionTime(time.getTime() / 1000).split(' ')[0]
      this.query.more.doViralTime = time.getTime() / 1000
      this.showPicker6 = false
    },
    onConfirm7(time) {
      this.tiemValue2 = this.conversionTime(time.getTime() / 1000).split(' ')[0]
      this.query.more.signingTime = time.getTime() / 1000
      this.showPicker7 = false
    },
    goBackClick() {
      this.$router.back()
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  background-color: #fff;
  .beizhu {
    padding: 15px;
    font-size: 14px;
    p {
      line-height: 25px;
    }
  }
  .btn {
    padding: 7.5px 30px;
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
